// import axios from "axios"
import axios from "../../../../constants/axios"

export const STATISTICS_FETCH = async (setStats, setLoading, username) => {
  try {
    const response = await axios.get(
      process.env.GATSBY_BASE_URL + `/api/candidate/stats/${username}/`
    )
    setStats(response.data)
    setLoading(false)
  } catch (err) {
    setLoading(false)
  }
}
