import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"

import { Row } from "react-bootstrap"

import VIEWS from "../../../images/icons/wdn-view.png"
import SHORTLIST from "../../../images/icons/wdn-shortlist.png"

import { STATISTICS_FETCH } from "./actions/statistics-fetch"

import "./statistics.scss"
import Spinner from "../../spinner/spinner"
import ViewsShortlist from "../../view-shortlist/views-shortlist"
import StatisticsBody from "./statistics-body"
import StatisticsHeader from "./statistics-header"
import { GlobalAuthContext } from "../../../context/authContext"

const CandidateStatistics = ({ stats, setStats }) => {
  const [isLoading, setLoading] = useState(false)

  const { dispatch } = useContext(GlobalAuthContext)

  useEffect(() => {
    setLoading(true)
    try {
      const username = JSON.parse(localStorage.getItem("user")).username
      STATISTICS_FETCH(setStats, setLoading, username, dispatch)
    } catch (error) { }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="candidate-statistics mt-0 mt-md-5">
      <div className="views-section-wrapper mb-5">
        <Row>
          {isLoading ? (
            <Spinner />
          ) : (
            <ViewsShortlist stat={stats.views} title="Views" icon={VIEWS} />
          )}
          {isLoading ? (
            <Spinner />
          ) : (
            <ViewsShortlist
              stat={stats.shortlisted}
              title="Shortlist"
              icon={SHORTLIST}
            />
          )}
        </Row>
      </div>
      <div className="shortlisted-section mb-5">
        <h5 className="mb-3">Recent Companies that shortlisted your profile</h5>
        <div className="shortlisted">
          {isLoading ? (
            <Spinner />
          ) : stats.lsc && stats.lsc.length !== 0 ? (
            <div className="border ">
              <StatisticsHeader />
              {stats.lsc.map((stat, index) => {
                return (
                  <Link to={"/company/detail/" + stat?.username} key={index}>
                    <StatisticsBody key={index} stat={stat} />
                  </Link>
                )
              })}
            </div>
          ) : (
            <p>You haven't been shortlisted yet.</p>
          )}
        </div>
      </div>
      <div className="viewed-section mb-5">
        <h5 className="mb-3">Recent Companies that viewed your profile</h5>
        <div className="viewed">
          {isLoading ? (
            <Spinner />
          ) : stats.lvc && stats.lvc.length !== 0 ? (
            <div className="border">
              <StatisticsHeader />
              {stats.lvc.map((stat, index) => {
                return (
                  <Link to={"/company/detail/" + stat?.username} key={index}>
                    <StatisticsBody key={index} stat={stat} />
                  </Link>
                )
              })}
            </div>
          ) : (
            <p>Your profile hasn't been viewed yet.</p>
          )}
        </div>
      </div>
    </div>
  )
}
export default CandidateStatistics
