import React from "react"

import { Col, Row } from "react-bootstrap"

const StatisticsHeader = () => {
  return (
    <div className="header border-bottom pl-md-4 d-none d-md-block">
      <Row>
        <Col xs="3">Company</Col>
        <Col xs="3">Name</Col>
        <Col xs="3">Industry</Col>
        <Col xs="3">Date</Col>
      </Row>
    </div>
  )
}

export default StatisticsHeader
