import React, { useEffect } from "react"

import { Row, Col } from "react-bootstrap"

import "./view-shortlist.scss"

const ViewsShortlist = ({ icon, title, stat, isLoading }) => {
  useEffect(() => {}, [isLoading])

  return (
    <Col>
      <div className="views-container border px-3 pt-3">
        <Row className="al">
          <Col md="4" lg="3">
            <div className="views-icon">
              <img src={icon} alt="wdn-views" />
            </div>
          </Col>
          <Col md="8" lg="6">
            <div className="views-info">
              <p>{title}</p>
              <ul>
                <li>Today: {stat && stat.today ? stat.today : 0}</li>
                <li>All Time: {stat && stat.total ? stat.total : 0}</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  )
}

export default ViewsShortlist
