import React, { useState, useEffect, useContext } from "react"

import { SIDEBAR_MENU_STEP } from "../../../constants/sidebar-menu"
import { CANDIDATE_STATISTICS_MENU } from "../../../constants/profile-menu"

import { GlobalAuthContext } from "../../../context/authContext"

import CandidateProfileLayout from "../../../components/layout/candidate-profile"
import CandidateStatistics from "../../../components/candidate-profile/statistics/statistics"

const StatisticsPage = ({ location }) => {
  const [stats, setStats] = useState({})
  const { state } = useContext(GlobalAuthContext)

  useEffect(() => { }, [state.isAuthenticated, state?.isEmailVerified]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CandidateProfileLayout
      menu={CANDIDATE_STATISTICS_MENU}
      sidebarMenu={SIDEBAR_MENU_STEP}
      activeSidebar="Dashboard"
      active="Dashboard"
      title="Dashboard"
      location={location}
    >
      <CandidateStatistics stats={stats} setStats={setStats} />
    </CandidateProfileLayout>
  )
}

export default StatisticsPage
