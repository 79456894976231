import React from "react"

import { Col, Row } from "react-bootstrap"

import { MONTHS } from "../../../constants/form"

const StatisticsBody = ({ stat }) => {
  const formatDate = date => {
    return (
      new Date(date).getDate() +
      " " +
      MONTHS[new Date(date).getMonth()] +
      " " +
      new Date(date).getFullYear()
    )
  }

  return (
    <div className="body border-bottom-large pl-md-4 p-2">
      <Row className="align-items-center">
        <Col xs="4" md="3">
          <Row>
            <Col xs="12">
              <div className="logo-container">
                {stat.icon && <img src={stat.icon} alt="company-logo" />}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="8" md="9">
          <Row className="align-items-center">
            <Col md="4">
              <span>
                <strong>{stat.name}</strong>
              </span>
            </Col>
            <Col md="4">
              <span>{stat.industry}</span>
            </Col>
            <Col md="4">
              <span>{formatDate(stat.date)}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default StatisticsBody
